export const usStyles = (theme) => ({
  container: {
    paddingLeft: 100,
    paddingRight: 100,
    marginTop: 20,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  grid: {
    margin: 0,
    paddingTop: 0,
  },
  title: {
    marginBottom: 4,
    marginTop: 20,
  },
  underline: {
    marginBottom: 30,
    height: 2,
    width: 60,
    backgroundColor: theme.palette.primary.main,
  },
  description: {
    marginBottom: 20,
    letterSpacing: 1,
  },
  item: {
    // maxHeight: 400,
    marginTop: 40,
    [theme.breakpoints.down("sm")]: {
      marginTop: 30,
    },
  },
  image: {
    width: "100%",
    paddingRight: 0,
    // marginBottom: 20,
  },
  accordion: {
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderTop: 0,
      borderLeft: 0,
      borderRight: 0,
    },
    "&:is(:last-child)": {
      borderTop: 0,
      borderLeft: 0,
      borderRight: 0,
    },
    "&::before": {
      display: "none",
    },
  },
});
