import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons/index";
import React from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./Testimonials.css";

const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: 40,
    marginBottom: 4,
  },
  underline: {
    marginBottom: 30,
    height: 2,
    width: 60,
    backgroundColor: theme.palette.primary.main,
  },
  text: {
    fontStyle: "italic",
    marginBottom: 16,
  },
}));

export const Testimonials = () => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <div style={{ paddingTop: 60, marginBottom: 60 }} id="testimonials">
      <Typography
        className={classes.title}
        variant="h4"
        color="secondary"
        align="center"
      >
        {t("testimonials")}
      </Typography>
      <Grid container justifyContent="center">
        <Box className={classes.underline} />
      </Grid>
      <div
        className="testimonial"
        style={{ display: "flex", justifyContent: "center", color: "white" }}
      >
        <div style={{ width: "50%", marginBottom: 60 }}>
          <Slider prevArrow={<PreviousBtn />} nextArrow={<NextBtn />} dots>
            <SliderCard
              textClassName={classes.text}
              testimony={`"${t("testimonial1")}"`}
              name="Mariana V."
            />
            <SliderCard
              textClassName={classes.text}
              testimony={`"${t("testimonial2")}"`}
              name="Daniel I."
            />
            <SliderCard
              textClassName={classes.text}
              testimony={`"${t("testimonial3")}"`}
              name="Karolina R."
            />
            <SliderCard
              textClassName={classes.text}
              testimony={`"${t("testimonial4")}"`}
              name="Rosangel P."
            />
          </Slider>
        </div>
      </div>
    </div>
  );
};

const PreviousBtn = (props) => {
  const { className, onClick } = props;

  return (
    <div className={className} onClick={onClick}>
      <ArrowBackIos style={{ color: "gray", fontSize: 40 }} />
    </div>
  );
};

const NextBtn = (props) => {
  const { className, onClick } = props;

  return (
    <div className={className} onClick={onClick}>
      <ArrowForwardIos style={{ color: "gray", fontSize: 40 }} />
    </div>
  );
};

const SliderCard = ({ textClassName, testimony, name }) => {
  return (
    <div
      className=""
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        marginTop: 0,
      }}
    >
      {/* <Avatar
        imgProps={{ style: { borderRadius: "50%" } }}
        src={img}
        style={{
          width: 140,
          height: 140,
          border: "1px solid lightgray",
          padding: 5,
        }}
      /> */}
      <div className="cardTextContainer">
        <Typography
          className={textClassName}
          variant="body1"
          color="textSecondary"
          align="center"
        >
          {testimony}
        </Typography>
        <Typography
          className={textClassName}
          variant="subtitle2"
          color="primary"
          align="center"
        >
          {name}
        </Typography>
      </div>
    </div>
  );
};
