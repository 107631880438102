import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
import React from "react";

import { useTranslation } from "react-i18next";
import asesoriasImg from "../../images/asesorias.png";
import clientesImg from "../../images/clientes.png";
import marcasPatentesImg from "../../images/marcas-patentes.png";
import bgImage from "../../images/stats_bg.png";

const useStyles = makeStyles((theme) => ({
  bg: {
    [theme.breakpoints.up("sm")]: {
      minHeight: 430,
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: 350,
    },
  },
  grid: {
    paddingTop: 30,
    paddingBottom: 30,
    margin: 0,
  },
  image: {
    maxWidth: 130,
    maxHeight: 50,
    [theme.breakpoints.down("md")]: {
      maxWidth: 100,
      maxHeight: 40,
    },
  },
  text: {
    color: "#fff",
  },
}));

export const StatsSection = () => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <div>
      <Box
        className={classes.bg}
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: `center`,
          backgroundRepeat: "no-repeat",
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box justifyContent="center" alignItems="center" display="flex">
          <Grid
            container
            className={classes.grid}
            spacing={8}
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Grid
              item
              md={4}
              sm={6}
              className={classes.item}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={2} sm={6}>
                  <Box>
                    <img
                      className={classes.image}
                      alt="marcas"
                      src={asesoriasImg}
                    />
                  </Box>
                </Grid>

                <Grid item xs={2} sm={6}>
                  <Typography
                    variant="h4"
                    align="center"
                    className={classes.text}
                  >
                    40K
                  </Typography>
                </Grid>
              </Grid>
              <Typography variant="h6" align="center" className={classes.text}>
                {t("brands")}
              </Typography>
            </Grid>
            <Grid
              item
              md={4}
              sm={6}
              className={classes.item}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={2} sm={6}>
                  <Box>
                    <img
                      className={classes.image}
                      alt="marcas"
                      src={marcasPatentesImg}
                    />
                  </Box>
                </Grid>

                <Grid item xs={2} sm={6}>
                  <Typography
                    variant="h4"
                    align="center"
                    className={classes.text}
                  >
                    2K
                  </Typography>
                </Grid>
              </Grid>
              <Typography variant="h6" align="center" className={classes.text}>
                {t("patents")}
              </Typography>
            </Grid>
            <Grid
              item
              md={4}
              sm={6}
              className={classes.item}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={0}
              >
                <Grid item xs={2} sm={6}>
                  <Box>
                    <img
                      className={classes.image}
                      alt="marcas"
                      src={clientesImg}
                    />
                  </Box>
                </Grid>

                <Grid item xs={2} sm={6}>
                  <Typography
                    variant="h4"
                    align="center"
                    className={classes.text}
                  >
                    +60K
                  </Typography>
                </Grid>
              </Grid>
              <Typography variant="h6" align="center" className={classes.text}>
                {t("happyClients")}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};
