import { Box } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  CloseIcon,
  Icon,
  SidebarContainer,
  SidebarLink,
  SidebarMenu,
  SidebarWrapper,
} from "./SidebarElements";

export const Sidebar = ({ isOpen, toggleSidebar }) => {
  const { t } = useTranslation();

  return (
    <SidebarContainer isOpen={isOpen} onClick={toggleSidebar}>
      <Icon onClick={toggleSidebar}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink
            to="us"
            onClick={toggleSidebar}
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
          >
            {t("menuUs")}
          </SidebarLink>
          <SidebarLink
            to="services"
            onClick={toggleSidebar}
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
          >
            {t("menuAreas")}
          </SidebarLink>
          <SidebarLink
            to="team"
            onClick={toggleSidebar}
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
          >
            {t("menuTeam")}
          </SidebarLink>
          <SidebarLink
            to="contact"
            onClick={toggleSidebar}
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
          >
            <Box
              style={{
                borderRadius: "25px",
                backgroundColor: "#742F8A",
                width: "200px",
                height: "50px",
                color: "#fff",
              }}
              color="primary"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {" "}
              {t("menuContact")}{" "}
            </Box>
          </SidebarLink>
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  );
};
