export const servicesStyles = (theme) => ({
  container: {
    backgroundColor: "white",
    borderRadius: "20px",
    marginTop: 60,
    paddingLeft: 60,
    paddingRight: 60,
    [theme.breakpoints.down("md")]: {
      marginTop: 40,
      paddingLeft: 40,
      paddingRight: 40,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  mainGrid: {
    paddingTop: 40,
    paddingBottom: 40,
  },
  grid: {
    // marginTop: 20,
    // paddingBottom: 40,
  },
  title: {
    paddingTop: 40,
    marginBottom: 4,
  },
  underline: {
    marginBottom: 30,
    height: 2,
    width: 60,
    backgroundColor: theme.palette.primary.main,
  },
  underlineGrid: {
    justifyContent: "flex-start",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  item: {
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 20,
      alignItems: "center",
    },
  },
  serviceColoredBox: {
    height: "40px",
    width: "40px",
    borderRadius: "15px",
    marginRight: "20px",
    alignSelf: "center",
    // marginBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "10px",
      marginLeft: "0px",
      minHeight: "30px",
      minWidth: "30px",
      borderRadius: "10px",
    },
  },
  image: {
    margin: "auto",
    width: "55%",
  },
  mainImage: {
    width: "100%",
    margin: "auto",
    borderRadius: 20,
    overflow: "hidden",
  },
  serviceTitle: {
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  accordion: {
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderTop: 0,
      borderLeft: 0,
      borderRight: 0,
    },
    "&:is(:last-child)": {
      borderTop: 0,
      borderLeft: 0,
      borderRight: 0,
    },
    "&::before": {
      display: "none",
    },
  },
  summary: {
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
    },
  },
  familyBusiness: {
    fontStyle: "italic",
    marginTop: 20,
    marginBottom: 8,
  },
});
