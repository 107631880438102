import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import { FaClock, FaEnvelope, FaPhone } from "react-icons/fa";
import { ContactForm } from "./ContactForm";
import { contactStyles } from "./contactStyles";

const useStyles = makeStyles((theme) => contactStyles(theme));

export const ContactSection = () => {
  const classes = useStyles();

  return (
    <Box id="contact" className={classes.container}>
      <Grid
        className={classes.grid}
        container
        spacing={4}
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid item xs={12} md={12} lg={5}>
          <ContactForm classes={classes} />
        </Grid>
        <Grid
          className={classes.info}
          item
          container
          xs={12}
          md={12}
          lg={3}
          direction="column"
        >
          <Grid item>
            <Typography
              className={classes.subtitle}
              variant="h6"
              color="secondary"
              align="left"
            >
              Sucursal Caracas
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              className={classes.description}
              variant="body2"
              color="textSecondary"
              align="left"
            >
              Edif. Centro Solano Plaza I, Piso 4, Oficina 4A., Urb. Sabana
              Grande. Caracas 1050, Venezuela.
            </Typography>
          </Grid>
          <Grid item container display="flex">
            <FaPhone color="#fff" />
            <Typography
              className={classes.contactInfo}
              variant="body2"
              color="textSecondary"
              align="left"
            >
              (+58) 212 7617674
            </Typography>
          </Grid>
          <Grid item container display="flex">
            <FaEnvelope color="#fff" />
            <Typography
              className={classes.contactInfo}
              variant="body2"
              color="textSecondary"
              align="left"
            >
              registros@ecv.com.ve
            </Typography>
          </Grid>
          <Grid item container display="flex">
            <FaClock color="#fff" />
            <Typography
              className={classes.contactInfo}
              variant="body2"
              color="textSecondary"
              align="left"
            >
              Lun - Vie: 07:30 am - 04.30pm
            </Typography>
          </Grid>
        </Grid>
        <Grid
          className={classes.info}
          item
          container
          xs={12}
          md={12}
          lg={3}
          direction="column"
        >
          <Grid item>
            <Typography
              className={classes.subtitle}
              variant="h6"
              color="secondary"
              align="left"
            >
              Sucursal Valencia
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              className={classes.description}
              variant="body2"
              color="textSecondary"
              align="left"
            >
              Urb San José de Tarbes, Av. 96 con Av. Paseo Cabriales, Torre BOD,
              piso 19, Oficina 19-8, Valencia 2001 - Estado Carabobo.
            </Typography>
          </Grid>
          <Grid item container display="flex">
            <FaPhone color="#fff" />
            <Typography
              className={classes.contactInfo}
              variant="body2"
              color="textSecondary"
              align="left"
            >
              (+58) 241 821 1395
            </Typography>
          </Grid>
          <Grid item container display="flex">
            <FaEnvelope color="#fff" />
            <Typography
              className={classes.contactInfo}
              variant="body2"
              color="textSecondary"
              align="left"
            >
              info@ecv.com.ve
            </Typography>
          </Grid>
          <Grid item container display="flex">
            <FaClock color="#fff" />
            <Typography
              className={classes.contactInfo}
              variant="body2"
              color="textSecondary"
              align="left"
            >
              Lun - Vie: 07:30 am - 04.30pm
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
