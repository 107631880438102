import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core";
import "./App.css";
import { AppRouter } from "./routers/AppRouter";

let theme = createTheme({
  palette: {
    primary: {
      main: "#742F8A",
    },
    secondary: {
      main: "#000",
    },
    background: {
      default: "#fff",
    },
  },
  typography: {
    fontFamily: "Inter",
    h2: {
      fontWeight: 500,
      letterSpacing: 2,
    },
    h3: {
      fontWeight: 500,
      letterSpacing: 2,
    },
    h6: {
      fontSize: 17,
    },
    button: {
      textTransform: "none",
    },
    subtitle1: {
      lineHeight: 1.2,
      fontWeight: 500,
    },
    body1: {
      fontSize: 16,
    },
    body2: {
      fontSize: 14,
      letterSpacing: 1,
    },
  },
});

theme = responsiveFontSizes(theme);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AppRouter />
    </ThemeProvider>
  );
}

export default App;
