export const teamStyles = (theme) => ({
  container: {
    marginTop: 60,
    paddingLeft: 60,
    paddingRight: 60,
    [theme.breakpoints.down("md")]: {
      marginTop: 40,
      paddingLeft: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  grid: {
    marginTop: 20,
    paddingBottom: 40,
  },
  title: {
    paddingTop: 40,
    marginBottom: 4,
  },
  underline: {
    marginBottom: 30,
    height: 2,
    width: 60,
    backgroundColor: theme.palette.primary.main,
  },
  item: {
    marginTop: 40,
    marginBottom: 20,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 20,
      marginTop: 20,
    },
  },
  image: {
    margin: "auto",
    width: "60%",
  },
  imageTeam: {
    margin: "auto",
    width: "80%",
    [theme.breakpoints.down("md")]: {
      width: "60%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "50%",
    },
  },
});
