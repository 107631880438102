import italyFlag from "../images/italy.png";
import portugalFlag from "../images/portugal.png";
import spainFlag from "../images/spain.png";
import usaFlag from "../images/usa.png";

export const LANGUAGES = [
  { label: "Spanish", code: "es", flag: spainFlag },
  { label: "English", code: "en", flag: usaFlag },
  { label: "Italian", code: "it", flag: italyFlag },
  { label: "Portuguese", code: "pt", flag: portugalFlag },
];
