import React from "react";
import {
  DevelopedBy,
  FooterContainer,
  FooterWrapper,
  WebsiteRigths,
} from "./FooterElements";

export const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrapper>
        <WebsiteRigths>
          ECV & Asociados © {new Date().getFullYear()} All rights reserved.
        </WebsiteRigths>

        <DevelopedBy
          href="//sappitotech.com/"
          target="_blank"
          arial-label="Twitter"
        >
          Developed by Sappito Tech
        </DevelopedBy>
      </FooterWrapper>
    </FooterContainer>
  );
};
