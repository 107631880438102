import { Box, MenuItem } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaBars } from "react-icons/fa";
import { animateScroll as scroll } from "react-scroll";
import { LANGUAGES } from "../../constants";
import imgColor from "../../images/logo_ecv.png";
import {
  FlagImg,
  FlagSelect,
  LogoImg,
  MobileIcon,
  MobileLogoImg,
  Nav,
  NavItem,
  NavLinks,
  NavLogo,
  NavMenu,
  NavbarContainer,
} from "./NavbarElements";

export const Navbar = ({ toggleSidebar }) => {
  const [scrollNav, setScrollNav] = useState(false);

  const { i18n, t } = useTranslation();

  const handleLanguageChange = (event) => {
    const langCode = event.target.value;
    i18n.changeLanguage(langCode);
  };

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <Nav scrollNav={scrollNav}>
        <NavbarContainer>
          <NavLogo to="/" onClick={toggleHome}>
            <LogoImg src={imgColor} />
            <MobileLogoImg src={imgColor} />
          </NavLogo>
          <MobileIcon onClick={toggleSidebar} scrollNav={scrollNav}>
            <FaBars />
          </MobileIcon>
          <NavMenu scrollNav={scrollNav}>
            <NavItem>
              <NavLinks
                to="us"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
              >
                {t("menuUs")}
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="services"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
              >
                {t("menuAreas")}
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="team"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
              >
                {t("menuTeam")}
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="contact"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
              >
                <Box
                  style={{
                    borderRadius: "25px",
                    backgroundColor: "#742F8A",
                    width: "150px",
                    height: "40px",
                    color: "#fff",
                  }}
                  color="primary"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  {" "}
                  {t("menuContact")}{" "}
                </Box>
              </NavLinks>
            </NavItem>
          </NavMenu>
          <FlagSelect
            defaultValue={"es"}
            onChange={handleLanguageChange}
            disableUnderline
          >
            {LANGUAGES.map(({ label, code, flag }) => (
              <MenuItem key={code} value={code}>
                <FlagImg src={flag} alt={label} />
              </MenuItem>
            ))}
          </FlagSelect>
        </NavbarContainer>
      </Nav>
    </>
  );
};
