import { Link as LinkS } from "react-scroll";
import styled from "styled-components";

export const FooterContainer = styled.section`
  width: 100%;
  background-color: #3e164a;
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  margin: 0px auto 0px auto;
  padding-top: 30px;
  padding-bottom: 30px;
  flex-direction: column;
`;

export const FooterLogo = styled(LinkS)`
  color: #fff;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-weight: bold;
  text-decoration: none;
`;

export const WebsiteRigths = styled.small`
  color: #ffff;
  margin-bottom: 16px;
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
  margin-bottom: 16px;
`;

export const SocialIconLink = styled.a`
  color: #fff;
  font-size: 24px;
`;

export const DevelopedBy = styled.a`
  color: #fff;
  font-size: 13px;
  text-decoration: underline;
  cursor: pointer;
`;

export const LogoImg = styled.img`
  width: 100px;
  margin-left: 25px;
  margin-bottom: 16px;
  object-fit: contain;
`;
