import { Box, Typography, makeStyles } from "@material-ui/core";
import React from "react";

import { useTranslation } from "react-i18next";
import bgImage from "../../images/intro-bg.png";

const useStyles = makeStyles((theme) => ({
  bg: {
    maxWidth: "md",
    [theme.breakpoints.up("sm")]: {
      minHeight: 650,
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: 500,
    },
  },
  textBox: {
    [theme.breakpoints.up("sm")]: {
      minHeight: 650,
      padding: 60,
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: 500,
      padding: 20,
    },
  },
  title: {
    color: "white",
    [theme.breakpoints.up("sm")]: {
      width: "85%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  description: {
    color: "white",
    marginTop: 20,
    [theme.breakpoints.up("sm")]: {
      maxWidth: "100vh",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100vh",
    },
  },
}));

export const Intro = () => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <div>
      <Box
        className={classes.bg}
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: `center`,
          m: 0,
          backgroundRepeat: "no-repeat",
          borderRadius: "20px",
        }}
      >
        <Box
          className={classes.textBox}
          justifyContent="flex-end"
          alignItems="flex-start"
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            className={classes.title}
            variant="h4"
            color="secondary"
            align="left"
          >
            {t("introTitle")}
          </Typography>
          <Typography
            className={classes.description}
            color="textSecondary"
            align="left"
            variant="body2"
          >
            {t("introDescription")}
          </Typography>
        </Box>

        {/* <Container maxWidth={false}>
          <Box
            display="flex"
            minHeight={900}
            alignItems="center"
            justifyContent="center"
            // maxWidth='xs'
            margin="auto"
          >
            <Typography
              className={classes.title}
              variant="h2"
              component="h1"
              align="center"
            >
              Diseñamos, Desarrollamos, Creamos
            </Typography>
          </Box>
        </Container> */}
      </Box>
    </div>
  );
};
