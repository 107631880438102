import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import { usStyles } from "./usStyles";

import { useTranslation } from "react-i18next";
import usImage from "../../images/us_image.png";

const useStyles = makeStyles((theme) => usStyles(theme));

export const UsSection = () => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <Container id="us" className={classes.container}>
      <Grid
        container
        className={classes.grid}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} md={6} className={classes.item}>
          <Box sx={{ borderRadius: "20px", overflow: "hidden" }}>
            <img className={classes.image} alt="ECV Nosotros" src={usImage} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            className={classes.title}
            variant="h4"
            color="secondary"
            align="left"
          >
            {t("usTitle")}
          </Typography>
          <Box className={classes.underline} />
          {/* <Typography
            className={classes.description}
            variant="body2"
            color="textSecondary"
            align="left"
          >
            {t("usDesc1")}
          </Typography>
          <Typography
            className={classes.description}
            variant="body2"
            color="textSecondary"
            align="left"
          >
            {t("usDesc2")}
          </Typography> */}
          <Accordion
            className={classes.accordion}
            elevation="0"
            disableGutters={true}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#742F8A" }} />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography variant="h6">{t("usHistoryTitle")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2" color="textSecondary">
                {t("usHistoryP1")}
                <br />
                <br />
                {t("usHistoryP2")}
                <br />
                <br />
                {t("usHistoryP3")}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className={classes.accordion}
            elevation="0"
            disableGutters={true}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#742F8A" }} />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography variant="h6">{t("usMisionTitle")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2" color="textSecondary">
                {t("usMisionP1")}
                <br />
                <br />
                {t("usMisionP2")}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className={classes.accordion}
            elevation="0"
            disableGutters={true}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#742F8A" }} />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography variant="h6">{t("usVisionTitle")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2" color="textSecondary">
                {t("usVisionP1")}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            className={classes.accordion}
            elevation="0"
            disableGutters={true}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#742F8A" }} />}
              aria-controls="panel3-content"
              id="panel3-header"
            >
              <Typography variant="h6">{t("usValuesTitle")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2" color="textSecondary">
                <div dangerouslySetInnerHTML={{ __html: t("usValuesP1") }} />
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Container>
  );
};
