import {
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "../../hooks/useForm";

export const ContactForm = ({ classes }) => {
  const { t } = useTranslation();
  const [{ name, email, message }, handleInputChange, reset] = useForm({
    name: "",
    email: "",
    message: "",
  });

  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [messageError, setMessageError] = useState("");

  const getRecaptchaScore = async (token) => {
    const url = `https://us-central1-sappito-tech-web-a72f6.cloudfunctions.net/sendRecaptcha?token=${token}`;
    const response = await fetch(url);
    console.log("response", response);
    const { score } = await response.json();
    console.log("score", score);
    return score;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setNameError("");
    setEmailError("");
    setMessageError("");

    if (name.trim().length <= 1) {
      console.log("Please input name");
      setNameError("Ingresa tu nombre completo");
      return;
    }

    if (email.trim().length <= 1) {
      console.log("Please input email");
      setEmailError("Ingresa tu correo electrónico");
      return;
    }

    if (message.trim().length <= 1) {
      console.log("Please input message");
      setMessageError("Debes ingresar un mensaje");
      return;
    }

    console.log("Calling reCaptcha!");
    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute("6LdkBSAqAAAAAAEGPF1nnWOWZXE3xJO539H3Nn0o", {
          action: "submit",
        })
        .then(function (token) {
          // Send form value as well as token to the server
          //   console.log(token)
          // getRecaptchaScore(token).then((data) => {
          //   console.log(data);
          // });
        });
    });

    reset();
  };

  return (
    <Container>
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container className={classes.grid} spacing={2}>
          <Grid item xs={12}>
            <Typography
              className={classes.title}
              variant="h5"
              color="secondary"
              align="left"
            >
              {t("menuContact")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.field}
              label={t("fullName")}
              placeholder={t("fullName")}
              variant="outlined"
              required
              fullWidth
              name="name"
              onChange={handleInputChange}
              value={name}
              error={nameError !== ""}
              helperText={nameError}
              InputLabelProps={{
                style: { color: "#fff", borderColor: "#fff" },
              }}
              InputProps={{ className: classes.fieldInput }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.field}
              label={t("email")}
              placeholder={t("email")}
              variant="outlined"
              type="email"
              required
              fullWidth
              name="email"
              onChange={handleInputChange}
              value={email}
              error={emailError !== ""}
              helperText={emailError}
              InputLabelProps={{
                style: { color: "#fff", borderColor: "#fff" },
              }}
              InputProps={{ className: classes.fieldInput }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.field}
              label={t("message")}
              variant="outlined"
              required
              fullWidth
              multiline
              minRows={4}
              placeholder={t("message")}
              name="message"
              onChange={handleInputChange}
              value={message}
              error={messageError !== ""}
              helperText={messageError}
              InputLabelProps={{
                style: { color: "#fff", borderColor: "#fff" },
              }}
              InputProps={{ className: classes.fieldInput }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              className={classes.button}
              type="submit"
              variant="contained"
              color="primary"
              size="large"
            >
              {" "}
              {t("send")}{" "}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};
