import React, { useState } from "react";
import "../App.css";
import { ContactSection } from "../components/ContactSection/ContactSection";
import { Footer } from "../components/Footer";
import { Intro } from "../components/Intro/Intro";
import { Navbar } from "../components/Navbar";
import { ServicesSection } from "../components/ServicesSection/ServicesSection";
import { Sidebar } from "../components/Sidebar";
import { StatsSection } from "../components/StatsSection/StatsSection";
import { TeamSection } from "../components/TeamSection/TeamSection";
import { Testimonials } from "../components/TestimonialsSection/Testimonials";
import { UsSection } from "../components/UsSection/UsSection";

export const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      <Navbar toggleSidebar={toggleSidebar} />
      <div className="mainRaised">
        <Intro />
        <UsSection />
        <ServicesSection />
        <TeamSection />
      </div>
      <StatsSection />
      <Testimonials />
      <ContactSection />
      <Footer />
    </>
  );
};
