export const contactStyles = (theme) => ({
  container: {
    backgroundColor: "#3E164A",
    marginTop: 80,
    // maxWidth: 1500,
    [theme.breakpoints.down("lg")]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  card: {
    justifyContent: "center",
    borderRadius: 60,
    paddingLeft: 40,
    paddingRight: 40,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  grid: {
    // margin: "auto",
    maxWidth: 1500,
  },
  info: {
    margin: 20,
  },
  title: {
    marginTop: 40,
    color: "#fff",
  },
  subtitle: {
    marginTop: 80,
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
  description: {
    color: "#fff",
    marginTop: 20,
    marginBottom: 20,
  },
  contactInfo: {
    color: "#fff",
    marginLeft: 8,
    marginBottom: 8,
  },
  field: {
    background: "rgb(255, 255, 255, 0.18)",
  },
  fieldInput: {
    color: "white",
  },
  button: {
    marginTop: 20,
  },
});
