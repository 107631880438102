import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { FaEnvelope } from "react-icons/fa";

export const BioDialog = ({
  bioKey,
  name,
  workTitle,
  image,
  email,
  isOpen,
  handleClose,
  classes,
}) => {
  // const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      maxWidth="md"
    >
      <DialogActions>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogActions>
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={3}>
            <Box display="flex">
              <img className={classes.image} alt="Enrique Cheang" src={image} />
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Typography variant="h6" align="left">
              {name}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary" align="left">
              {workTitle}
            </Typography>
            {email && (
              <Link href={`mailto:${email}`}>
                <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  align="left"
                >
                  <FaEnvelope /> {email}
                </Typography>
              </Link>
            )}
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent dividers>
        <Box sx={{ m: 5 }}>
          <Typography variant="body2" color="textSecondary">
            <div
              dangerouslySetInnerHTML={{
                __html: t(bioKey),
              }}
            />
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
