import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import { servicesStyles } from "./servicesStyles";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import bankIcon from "../../images/bank.svg";
import buildingIcon from "../../images/building.svg";
import glassesIcon from "../../images/glasses.png";
import globeIcon from "../../images/globe.svg";
import servicesImage from "../../images/services_image.png";
import tagIcon from "../../images/tag.svg";

const useStyles = makeStyles((theme) => servicesStyles(theme));

export const ServicesSection = () => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <Container id="services" className={classes.container}>
      <Grid
        container
        className={classes.mainGrid}
        spacing={4}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          md={6}
          xs={12}
          container
          // className={classes.grid}
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Typography
            className={classes.serviceTitle}
            variant="h4"
            color="secondary"
          >
            {t("servicesTitle")}
          </Typography>
          <Grid className={classes.underlineGrid} container>
            <Box className={classes.underline} />
          </Grid>
          <Grid item justifyContent="center" alignItems="flex-start">
            <Accordion
              className={classes.accordion}
              elevation="0"
              disableGutters={true}
            >
              <AccordionSummary
                className={classes.summary}
                expandIcon={<ExpandMoreIcon sx={{ color: "#742F8A" }} />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Box
                  className={classes.serviceColoredBox}
                  display="flex"
                  sx={{ backgroundColor: "#742F8A" }}
                >
                  <img
                    className={classes.image}
                    alt="Propiedad Intelectual"
                    src={glassesIcon}
                  />
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography className={classes.serviceTitle} variant="h6">
                    {t("serviceIntellectualProperty")}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box flexDirection="column">
                  <Typography variant="subtitle1" color="primary">
                    {t("servicePI1")}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t("servicePI1Items"),
                      }}
                    />
                  </Typography>
                  <Typography variant="subtitle1" color="primary">
                    {t("servicePI2")}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t("servicePI2Items"),
                      }}
                    />
                  </Typography>
                  <Typography variant="subtitle1" color="primary">
                    {t("servicePI3")}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t("servicePI3Items"),
                      }}
                    />
                  </Typography>
                  <Typography variant="subtitle1" color="primary">
                    {t("servicePI4")}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t("servicePI4Items"),
                      }}
                    />
                  </Typography>
                  <Typography variant="subtitle1" color="primary">
                    {t("servicePI5")}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t("servicePI5Items"),
                      }}
                    />
                  </Typography>
                  <Typography variant="subtitle1" color="primary">
                    {t("servicePI6")}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t("servicePI6Items"),
                      }}
                    />
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion
              className={classes.accordion}
              elevation="0"
              disableGutters={true}
            >
              <AccordionSummary
                className={classes.summary}
                expandIcon={<ExpandMoreIcon sx={{ color: "#742F8A" }} />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Box
                  className={classes.serviceColoredBox}
                  display="flex"
                  sx={{ backgroundColor: "#742F8A" }}
                >
                  <img
                    className={classes.image}
                    alt="Derecho Corporativo"
                    src={buildingIcon}
                  />
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="h6">
                    {t("serviceCorporateLaw")}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box flexDirection="column">
                  <Typography variant="subtitle1" color="primary">
                    {t("serviceCL1")}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t("serviceCL1Items"),
                      }}
                    />
                  </Typography>
                  <Typography
                    className={classes.familyBusiness}
                    variant="h6"
                    color="primary"
                  >
                    Family Business
                  </Typography>
                  <Typography variant="subtitle1" color="primary">
                    {t("serviceCL2")}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t("serviceCL2Items"),
                      }}
                    />
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion
              className={classes.accordion}
              elevation="0"
              disableGutters={true}
            >
              <AccordionSummary
                className={classes.summary}
                expandIcon={<ExpandMoreIcon sx={{ color: "#742F8A" }} />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Box
                  className={classes.serviceColoredBox}
                  display="flex"
                  sx={{ backgroundColor: "#742F8A" }}
                >
                  <img
                    className={classes.image}
                    alt="Derecho Regulatorio"
                    src={tagIcon}
                  />
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="h6">
                    {t("serviceRegulationsLaw")}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box flexDirection="column">
                  <Typography variant="body2" color="textSecondary">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t("serviceRL1Items"),
                      }}
                    />
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion
              className={classes.accordion}
              elevation="0"
              disableGutters={true}
            >
              <AccordionSummary
                className={classes.summary}
                expandIcon={<ExpandMoreIcon sx={{ color: "#742F8A" }} />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Box
                  className={classes.serviceColoredBox}
                  display="flex"
                  sx={{ backgroundColor: "#742F8A" }}
                >
                  <img
                    className={classes.image}
                    alt="Franquicias"
                    src={globeIcon}
                  />
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="h6">{t("serviceFranchise")}</Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box flexDirection="column">
                  <Typography variant="body2" color="textSecondary">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t("serviceF1Items"),
                      }}
                    />
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion
              className={classes.accordion}
              elevation="0"
              disableGutters={true}
            >
              <AccordionSummary
                className={classes.summary}
                expandIcon={<ExpandMoreIcon sx={{ color: "#742F8A" }} />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Box
                  className={classes.serviceColoredBox}
                  display="flex"
                  sx={{ backgroundColor: "#742F8A" }}
                >
                  <img
                    className={classes.image}
                    alt="Banca y Finanzas"
                    src={bankIcon}
                  />
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="h6">{t("serviceFinance")}</Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box flexDirection="column">
                  <Typography variant="body2" color="textSecondary">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t("serviceFin1Items"),
                      }}
                    />
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} className={classes.item}>
          <Box>
            <img
              className={classes.mainImage}
              alt="ECV Nosotros"
              src={servicesImage}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
